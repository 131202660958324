import { RoomState } from "../shared/ClientState";
import {
  Play,
  RPSGameState,
  RPSRequest,
  RPSResponse,
} from "../../../backend/src/games/rps/rpstypes";

interface Props {
  state: RoomState<RPSGameState, RPSRequest>;
  send: (msg: RPSResponse) => any;
}

const PRETTY_PLAY: { [key in Play]: string } = {
  rock: "Rock 🪨",
  paper: "Paper 📄",
  scissors: "Scissors ✂️",
};

export default function RockPaperScissors(props: Props) {
  const { state, send } = props;
  const { requestState, playerInfo } = state;

  // we are being asked to play something...
  let req = requestState.currentRequest;
  if (req !== null) {
    switch (req.kind) {
      case "move":
        return (
          <div>
            {Object.entries(PRETTY_PLAY).map(([moveName, prettyName], i) => {
              return (
                <button
                  onClick={() => {
                    send({ kind: "select-move", data: moveName as Play });
                  }}
                  key={i}
                >
                  {prettyName}
                </button>
              );
            })}
          </div>
        );

      case "outcome":
        return (
          <div>
            <div>
              {req.plays.map((play, i) => {
                return (
                  <div>
                    {playerInfo[i].username} played {PRETTY_PLAY[play]}
                  </div>
                );
              })}
            </div>
            <button
              onClick={() => {
                send({ kind: "continue" });
              }}
            >
              Continue
            </button>
          </div>
        );
    }
  }

  return <div>Waiting on others...</div>;
}
