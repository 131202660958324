interface Props {
  pickedLetters: string[];
  lettterSet: string[];
  onPick: (s: string) => any;
  disabled?: boolean;
}

export default function LetterPicker(props: Props) {
  return (
    <div>
      {props.lettterSet.map((letter) => {
        const hasBeenPicked = props.pickedLetters.includes(letter);
        return (
          <input
            type="button"
            value={letter}
            disabled={hasBeenPicked || props.disabled}
            style={hasBeenPicked ? {color: "rgba(0,0,0,0.1)"} : undefined}
            key={letter}
            onClick={() => props.onPick(letter)}
          />
        );
      })}
    </div>
  );
}
