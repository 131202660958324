import { Card54 } from "../shared/Cards";
import Card from "./Card";

interface Props {
  onSelect?: (i: number) => any;
  /**
   * If specified, the cards lining up with this boolean array
   * are selected. If undefined, no cards are selected.
   */
  selected?: boolean[];
  /**
   * The cards to display, or how many to show the
   * back of (e.g. for other player's hands)
   */
  cards: Card54[] | number;
}

export default function Cards(props: Props) {
  const { cards, onSelect, selected } = props;

  let els = [];
  // backs of cards, show no detail
  if (typeof cards === "number") {
    for (let i = 0; i < cards; i++) {
      els.push(
        <Card
          key={i}
          card={null}
          selected={false}
          selectable={!!onSelect}
          onClick={onSelect ? () => onSelect(i) : undefined}
        />
      );
    }
    return <div className="flex-row card-row">{els}</div>;
  } else {
    // front of cards, each one has detail
    els = cards.map((card, i) => {
      return (
        <Card
          key={i}
          card={card}
          selectable={!!onSelect}
          selected={selected === undefined ? false : selected[i]}
          onClick={onSelect ? () => onSelect(i) : undefined}
        />
      );
    });
  }

  return <div className="flex-row card-row">{els}</div>;
}
