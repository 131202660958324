import { useMemo, useState } from "react";
import "./App.css";

import { RPSGameState, RPSRequest } from "../../backend/src/games/rps/rpstypes";
import GameSelect from "./components/GameSelect";
import Idle from "./components/Idle";
import StatusWrapper from "./components/StatusWrapper";
import KillTheLandlord from "./games/KillTheLandlord";
import RockPaperScissors from "./games/RockPaperScissors";
import SurvivalHangman from "./games/SurvivalHangman";
import { CleanMessage, GameName, RoomState } from "./shared/ClientState";

function myUID() {
  let uid = sessionStorage.getItem("uid");
  if (uid === null) {
    uid = crypto.randomUUID();
    sessionStorage.setItem("uid", uid);
  }
  return uid;
}

function App() {
  const [state, setState] = useState<RoomState<any, any>>({
    gameName: "",
    playerInfo: [],
    messageLog: [],
    requestState: {
      currentRequest: null,
      waitingOnPlayer: [],
    },
    index: 0,
    playerGameState: {},
  });

  const [connectedState, setConnected] = useState<
    "connecting" | "connected" | "lost"
  >("connecting");

  const urlParams = new URLSearchParams(window.location.search);
  const roomId = urlParams.get("room");

  const ws = useMemo(() => {
    if (roomId === null) {
      let uint32 = new Uint32Array(1);
      window.crypto.getRandomValues(uint32);
      window.location.href += `?room=${uint32[0]}`;
      return;
    }

    if (roomId.length > 16) {
      console.error("bad room id");
      return;
    }

    let wsurl = `wss://${window.location.host}/ws`;

    // lazy dev logic
    if (window.location.hostname === "localhost")
      wsurl = `ws://localhost:8000/ws`;

    const ws = new WebSocket(wsurl);

    ws.addEventListener("open", () => {
      setConnected("connected");
      ws.send(JSON.stringify({ uid: myUID(), roomId }));
    });

    ws.addEventListener("message", (ev) => {
      const newState: RoomState<any, any> = JSON.parse(ev.data as string);
      console.log(newState);
      setState(newState);
    });

    ws.addEventListener("close", () => {
      setConnected("lost");
      console.warn("connection lost 🙁");
    });

    // export websocket for my own debugging
    (window as any).ws = ws;

    return ws;
  }, [setState, roomId]);

  function send(msg: CleanMessage) {
    ws?.send(JSON.stringify(msg));
  }

  function onGameSelect(game: GameName): void {
    send({
      kind: "start-game",
      data: game,
    });
  }

  // Connected status: hide if not connected
  if (connectedState === "connecting") {
    return (
      <div className="App">
        Connecting... <Idle />
      </div>
    );
  } else if (connectedState === "lost") {
    return <div className="App">Lost connection! Try refreshing?</div>;
  }

  // We are connected, then! Let's show the users and game!

  let inner = <></>;
  switch (state.gameName as GameName | "") {
    case "rock-paper-scissors":
      inner = (
        <RockPaperScissors
          state={state as RoomState<RPSGameState, RPSRequest>}
          send={send}
        />
      );
      break;
    case "kill-the-landlord":
      inner = <KillTheLandlord state={state} send={send} />;
      break;
    case "survival-hangman":
      inner = <SurvivalHangman state={state} send={send} />;
      break;
    case "":
      // select a game!
      inner = (
        <GameSelect playerInfo={state.playerInfo} onGameSelect={onGameSelect} me={state.index}/>
      );
      break;
    default:
      throw new Error("bad game name")
  }
  return (
    <div className="App">
      <StatusWrapper
        state={state}
        onNameChange={(newName) => {
          send({
            kind: "set-name",
            // also slices server-side
            data: newName.slice(0, 32),
          });
        }}
        onKick={(id) => {
          send({
            kind: "kick-player",
            data: id,
          });
        }}
      >
        {inner}
      </StatusWrapper>
    </div>
  );
}

export default App;
