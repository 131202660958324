import { RoomState } from "../shared/ClientState";
import { KTLPlayerGameState, KTLRequest, KTLResponse } from "./ktl/ktltypes";
import Cards from "../components/Cards";
import MeldPicker from "./ktl/MeldPicker";
import { MELD_PRETTY_NAMES } from "./ktl/melds";

interface Props {
  state: RoomState<KTLPlayerGameState, KTLRequest>;
  send: (msg: KTLResponse) => any;
}

export default function KillTheLandlord(props: Props) {
  let { state, send } = props;

  let myHand = (
    <Cards cards={state.playerGameState.hand} key={state.index} selected={[]} />
  );
  // for passing/becoming landlord
  let actionBar: JSX.Element[] = [];

  const topMeld = state.playerGameState.topMeld;
  const req = state.requestState.currentRequest;
  if (req !== null) {
    // we are being waited on - why?
    if (req.kind === "move") {
      // change hand to meld selection (from boring card selection)
      myHand = (
        <MeldPicker
          cards={state.playerGameState.hand}
          onPick={(cards, meldKind) => {
            send({
              kind: "play-meld",
              data: {
                meldKind,
                cards,
              },
            });
          }}
        />
      );
      // Add pass button as an action
      actionBar.push(
        <input
          type="button"
          value="Pass"
          onClick={() =>
            send({
              kind: "pass",
            })
          }
        />
      );
    } else if (req.kind === "become-landlord") {
      // Add pass button as an action
      actionBar.push(
        <input
          type="button"
          value="Become Landlord"
          onClick={() =>
            send({
              kind: "become-landlord",
              data: true,
            })
          }
        />
      );
      // Add pass button as an action
      actionBar.push(
        <input
          type="button"
          value="Pass"
          onClick={() =>
            send({
              kind: "become-landlord",
              data: false,
            })
          }
        />
      );
    } else {
      // unreachable!
      throw new Error("bad request state");
    }
  }

  let allHands = [];
  for (let i = 0; i < 3; i++) {
    let playerIdx = state.playerGameState.playerOrder[i];
    let isLandlord = playerIdx === state.playerGameState.landlord;
    let username = state.playerInfo[playerIdx].username;

    if (playerIdx === state.index) {
      // this is me
      allHands[i] = (
        <span>
          <span>
            {username} {isLandlord ? "(Landlord)" : "(Player)"}
          </span>
          {myHand}
        </span>
      );
    } else {
      // someone else
      allHands[i] = (
        <span>
          <span>
            {username} {isLandlord ? "(Landlord)" : "(Player)"}
          </span>
          <Cards cards={state.playerGameState.handSizes[playerIdx]} />
        </span>
      );
    }
  }

  let topMeldInfo: JSX.Element | null = null;
  if (topMeld !== null) {
    topMeldInfo = (
      <div>
        Meld: {MELD_PRETTY_NAMES[topMeld.kind]}, Played by:
        {state.playerInfo[topMeld.player].username}
        <Cards cards={topMeld.cards} />
      </div>
    );
  }

  return (
    <div>
      {allHands}
      {actionBar}
      {topMeldInfo}
    </div>
  );
}
