import { ClientData, fillUsernames } from "../shared/ClientState";
import "./MessageLog.css";

interface Props {
    messages: string[];
    playerInfo: ClientData[];
}

export default function MessageLog(props: Props) {
    return <div className="shadowed message-log">
        {props.messages.map((message, i) => {
            return <div key={i} style={{order: -i}}className="message-log-item">{fillUsernames(message, props.playerInfo)}</div>
        })}
    </div>
}