import { useState } from "react";
import "./SubmittableTextInput.css";

interface Props {
  placeholder?: string;
  confirmText: string;
  disabled?: boolean;
  onSubmit: (text: string) => void;
}

export default function SubmittableTextInput(props: Props) {
  const { onSubmit, placeholder, confirmText, disabled } = props;
  const [text, setText] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(text);
      }}
      className="text-submit-form"
    >
      <input
        type="text"
        placeholder={placeholder}
        value={text}
        onChange={(ev) => {
          setText(ev.target.value);
        }}
        disabled={disabled}
      />
      <input type="submit" value={confirmText} disabled={disabled} />
    </form>
  );
}
