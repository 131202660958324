import { useState } from "react";
import Cards from "../../components/Cards";
import { Card54 } from "../../shared/Cards";
import { isValidMeld, MeldKind, MELD_KINDS, MELD_PRETTY_NAMES } from "./melds";

interface Props {
  cards: Card54[];
  onPick: (cards: Card54[], meldKind: MeldKind) => void;
}

export default function MeldPicker(props: Props) {
  const { cards, onPick } = props;
  const [selected, setSelected] = useState(cards.map(() => false));

  const selectedCards = cards.filter((_, i) => selected[i]);
  let validMelds: MeldKind[] = MELD_KINDS.filter((meldKind) =>
    isValidMeld(selectedCards, meldKind)
  );

  return (
    <div>
      <Cards
        cards={cards}
        onSelect={(i) => {
          setSelected((s) => {
            const newSel = s.slice();
            newSel[i] = !newSel[i];
            return newSel;
          });
        }}
        selected={selected}
      />
      <div>
        {validMelds.map((meldKind, i) => {
          return (
            <input
              key={i}
              type="button"
              value={`Play cards as: ${MELD_PRETTY_NAMES[meldKind]}`}
              onClick={() => {
                onPick(selectedCards, meldKind);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
