import {
  Card54,
  CardNumber,
  CARD_NUMBER_MAP,
  multiplicity,
} from "../../shared/Cards";

// yoinked from https://web.archive.org/web/20151127163006/http://www.pagat.com/climbing/doudizhu.html

export type MeldKind =
  | "single"
  | "pair"
  | "triplet"
  | "triplet1"
  | "triplet2"
  | "sequence"
  | "sequence2"
  | "sequence3"
  | "sequence3_1"
  | "sequence3_2"
  | "bomb"
  | "rocket"
  | "quad1x2"
  | "quad2x1";
export const MELD_PRETTY_NAMES: { [k in MeldKind]: string } = {
  single: "Singles",
  pair: "Pairs",
  triplet: "Triplets",
  triplet1: "Triplets with an attached single",
  triplet2: "Triplets with an attached pair",
  sequence: "Sequences",
  sequence2: "Sequences of pairs",
  sequence3: "Sequences of triplets",
  sequence3_1: "Sequences of triplets with attached singles",
  sequence3_2: "Sequences of triplets with attached pairs",
  bomb: "Bombs",
  rocket: "Rockets",
  quad1x2: "Quadplexs set with two singles",
  quad2x1: "Quadplexs set with one pair",
};

export const MELD_KINDS = Object.keys(MELD_PRETTY_NAMES) as MeldKind[];

interface SpecificMeld<K extends MeldKind> {
  kind: K;
  /** The cards, in arbitrary order, present in this meld */
  cards: Card54[];
}

export type Meld =
  | (SpecificMeld<"single"> & { value: CardNumber })
  | (SpecificMeld<"pair"> & { value: CardNumber })
  | (SpecificMeld<"triplet"> & { value: CardNumber })
  | (SpecificMeld<"triplet1"> & { value: CardNumber; kicker: CardNumber })
  | (SpecificMeld<"triplet2"> & { value: CardNumber; kicker: CardNumber })
  | (SpecificMeld<"sequence"> & { start: CardNumber; length: number })
  | (SpecificMeld<"sequence2"> & { start: CardNumber; length: number })
  | (SpecificMeld<"sequence3"> & { start: CardNumber; length: number })
  | (SpecificMeld<"sequence3_1"> & {
      start: CardNumber;
      length: number;
      kickers: CardNumber[];
    })
  | (SpecificMeld<"sequence3_2"> & {
      start: CardNumber;
      length: number;
      kickers: CardNumber[];
    })
  | (SpecificMeld<"bomb"> & { value: CardNumber })
  | SpecificMeld<"rocket">
  | (SpecificMeld<"quad1x2"> & {
      value: CardNumber;
      kickers: [CardNumber, CardNumber];
    })
  | (SpecificMeld<"quad2x1"> & { value: CardNumber; kicker: CardNumber });

/**
 * Take a list of cards and convert into a Meld, which is unordered and specifies which type of combination is intended
 * @param cards The cards to convert into the specified meld
 * @param kind The kind of meld to try converting to
 * @returns The `Meld` containing (a copy of) these cards, or null if `cards` do not form a meld of `meldKind`.
 */
export function convertCardsToMeld(
  cards: Card54[],
  kind: MeldKind
): Meld | null {
  // drop suits, we don't care about them
  const cardNumbers = cards.map((card) => CARD_NUMBER_MAP[card]);
  const mult = multiplicity(cardNumbers);
  const uniqueCardCount = Object.keys(mult).length;

  switch (kind) {
    case "single":
      if (cards.length !== 1) return null;
      return {
        kind,
        cards,
        value: cardNumbers[0],
      };
    case "pair":
      // more than one card type? or not exactly two cards? drop it
      if (uniqueCardCount !== 1 || cards.length !== 2) return null;
      if (cardNumbers[0] === "joker") return null; // should be a rocket instead
      return {
        kind,
        cards,
        value: cardNumbers[0],
      };
    case "triplet":
      // more than one card type? or not exactly three cards? drop it
      if (uniqueCardCount !== 1 || cards.length !== 3) return null;
      return {
        kind,
        cards,
        value: cardNumbers[0],
      };
    case "bomb":
      // more than one card type? or not exactly four cards? drop it
      if (uniqueCardCount !== 1 || cards.length !== 4) return null;
      return {
        kind,
        cards,
        value: cardNumbers[0],
      };
    case "rocket":
      if (uniqueCardCount !== 1 || cards.length !== 2) return null;
      if (cardNumbers[0] !== "joker") return null;
      return {
        kind,
        cards,
      };
    case "triplet1": {
      let triple = null;
      let single = null;
      if (Object.keys(mult).length !== 2) return null;
      for (let cardNum in mult) {
        if (mult[cardNum as CardNumber] === 3) {
          triple = cardNum as CardNumber;
        }
        if (mult[cardNum as CardNumber] === 1) {
          single = cardNum as CardNumber;
        }
      }

      if (triple === null || single === null) return null;

      return {
        kind,
        cards,
        value: triple,
        kicker: single,
      };
    }
    case "triplet2": {
      if (Object.keys(mult).length !== 2) return null;
      let triple = null;
      let double = null;
      for (let cardNum in mult) {
        if (mult[cardNum as CardNumber] === 3) {
          triple = cardNum as CardNumber;
        }
        if (mult[cardNum as CardNumber] === 2) {
          double = cardNum as CardNumber;
        }
      }

      if (triple === null || double === null) return null;

      return {
        kind,
        cards,
        value: triple,
        kicker: double,
      };
    }
    case "quad1x2": {
      if (Object.keys(mult).length !== 3) return null;

      let quad = null;
      let singles: CardNumber[] = [];

      for (let cardNum in mult) {
        if (mult[cardNum as CardNumber] === 4) {
          quad = cardNum as CardNumber;
        }
        if (mult[cardNum as CardNumber] === 1) {
          singles.push(cardNum as CardNumber);
        }
      }

      if (quad === null || singles.length !== 2) return null;

      return {
        kind,
        cards,
        value: quad,
        kickers: [singles[0], singles[1]],
      };
    }
    case "quad2x1": {
      if (Object.keys(mult).length !== 3) return null;

      let quad = null;
      let double = null;
      for (let cardNum in mult) {
        if (mult[cardNum as CardNumber] === 4) {
          quad = cardNum as CardNumber;
        }
        if (mult[cardNum as CardNumber] === 2) {
          double = cardNum as CardNumber;
        }
      }

      if (quad === null || double === null) return null;

      return {
        kind,
        cards,
        value: quad,
        kicker: double,
      };
    }
    case "sequence": {
      let seq = extractSequence(cardNumbers, 1);
      if (seq === null) return null;

      var { sequence, leftovers, length, start } = seq;

      // min length 5
      if (Object.keys(sequence).length < 5) return null;
      // no leftovers allowed
      if (Object.keys(leftovers).length !== 0) return null;

      return {
        kind,
        cards,
        length,
        start,
      };
    }
    case "sequence2": {
      let seq = extractSequence(cardNumbers, 2);
      if (seq === null) return null;

      let { sequence, leftovers, length, start } = seq;

      // min length 3
      if (Object.keys(sequence).length < 3) return null;
      // no leftovers allowed
      if (Object.keys(leftovers).length !== 0) return null;

      return {
        kind,
        cards,
        length,
        start,
      };
    }
    case "sequence3": {
      let seq = extractSequence(cardNumbers, 3);
      if (seq === null) return null;

      let { sequence, leftovers, length, start } = seq;

      // min length 2
      if (Object.keys(sequence).length < 2) return null;
      // no leftovers allowed
      if (Object.keys(leftovers).length !== 0) return null;

      return {
        kind,
        cards,
        length,
        start,
      };
    }
    case "sequence3_1": {
      let seq = extractSequence(cardNumbers, 3);
      if (seq === null) return null;

      let { sequence, leftovers, length, start } = seq;

      // min length 2
      if (Object.keys(sequence).length < 2) return null;
      // leftovers must be singles matching seq length
      if (!Object.values(leftovers).every((v) => v === 1)) return null;
      if (Object.keys(leftovers).length !== length) return null;

      return {
        kind,
        cards,
        length,
        start,
        kickers: Object.keys(leftovers) as CardNumber[],
      };
    }
    case "sequence3_2": {
      let seq = extractSequence(cardNumbers, 3);
      if (seq === null) return null;

      let { sequence, leftovers, length, start } = seq;

      // min length 2
      if (Object.keys(sequence).length < 2) return null;
      // leftovers must be pairs matching seq length
      if (!Object.values(leftovers).every((v) => v === 2)) return null;
      if (Object.keys(leftovers).length !== length) return null;

      return {
        kind,
        cards,
        length,
        start,
        kickers: Object.keys(leftovers) as CardNumber[],
      };
    }
  }
}

function extractSequence(cardNumbers: CardNumber[], seqMult: number) {
  // only singles, i.e. if some multiplicity isn't one
  let mults = multiplicity(cardNumbers);

  // only those that match the desired multiplicity
  let seqMults: typeof mults = Object.fromEntries(
    Object.entries(mults).filter(([_k, v]) => v === seqMult)
  );

  // everything else...
  let leftovers: typeof mults = Object.fromEntries(
    Object.entries(mults).filter(([_k, v]) => v !== seqMult)
  );

  let seqTiers = (Object.keys(seqMults) as CardNumber[]).map(
    (cardNum) => KTL_SEQ_ORDER[cardNum]
  );

  // if some card is incompatible with sequences, stop
  if (seqTiers.some((v) => v === undefined)) return null;
  let start = Math.min(...(seqTiers as number[]));
  let length = Object.keys(seqMults).length;
  for (let i = 0; i < length; i++) {
    let expected = start + i;
    if (!seqTiers.includes(expected)) return null;
  }

  return {
    sequence: seqMults,
    leftovers,
    start: cardNumbers.find((cardNum) => KTL_SEQ_ORDER[cardNum] === start)!,
    length,
  };
}

export function isValidMeld(cards: Card54[], meldKind: MeldKind): boolean {
  return convertCardsToMeld(cards, meldKind) !== null;
}

const KTL_SEQ_ORDER: { [cardNum in CardNumber]: number | undefined } = {
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  jack: 11,
  queen: 12,
  king: 13,
  ace: 14,
  "2": undefined,
  joker: undefined,
};

export const KTL_VALUE: { [key in CardNumber]: number } = {
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  jack: 11,
  queen: 12,
  king: 13,
  ace: 14,
  "2": 15,
  joker: 16, // technically one joker beats the other
};

/** Whether `thisMeld` can be played on top of `topMeld` */
export function canPlayAtop(topMeld: Meld | null, thisMeld: Meld): boolean {
  // can play anything on top of empty pile
  if (topMeld === null) return true;

  // double joker beats anything
  if (thisMeld.kind === "rocket") return true;
  // and nothing can beat it.
  if (topMeld.kind === "rocket") return false;

  // bomb beats anything except a lower bomb (or a joker pair, but we've accounted for that above already)
  if (thisMeld.kind === "bomb") {
    if (topMeld.kind === "bomb") {
      return KTL_VALUE[topMeld.value] < KTL_VALUE[thisMeld.value];
    }
    // not a quad? (or a joker pair, see above)? Then we beat it
    return true;
  }

  // outisde of bombs, meld kinds must match completely to be played
  if (topMeld.kind !== thisMeld.kind) return false;

  switch (thisMeld.kind) {
    case "single":
      // Big joker beats little joker, even though they technically are the same
      // "rank" in generic terms
      if (topMeld.cards[0] === "j" && thisMeld.cards[0] === "J") return true;
      // otherwise, having the same card value means the prexisting one wins.
      // needs to be strictly beaten
      return (
        KTL_VALUE[(topMeld as any).value as CardNumber] <
        KTL_VALUE[thisMeld.value]
      );
    case "pair":
    case "triplet":
    case "triplet1":
    case "triplet2":
    case "quad1x2":
    case "quad2x1":
      return (
        KTL_VALUE[(topMeld as any).value as CardNumber] <
        KTL_VALUE[thisMeld.value]
      );
    case "sequence":
    case "sequence2":
    case "sequence3":
    case "sequence3_1":
    case "sequence3_2":
      // lengths must match to play sequence atop!
      if (thisMeld.length !== (topMeld as any).length) return false;
      return (
        KTL_VALUE[(topMeld as any).start as CardNumber] <
        KTL_VALUE[thisMeld.start]
      );
  }
}
