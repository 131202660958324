import { Card54 } from "../shared/Cards";
import "./Card.css";

interface Props {
  selectable: boolean;
  selected: boolean;
  onClick?: () => void;
  /** Null if back of card */
  card: Card54 | null;
}

export default function Card(props: Props) {
  const { card, onClick, selected, selectable } = props;

  let cardNumberSymbol = card === null ? "??" : card.slice(0, card.length - 2);
  let cardSuit = card?.slice(card.length - 2);

  if (cardSuit === "j" || cardSuit === "J") {
    cardNumberSymbol = cardSuit;
    cardSuit = "";
  }

  const color = cardSuit === "♦️" || cardSuit === "♥️" ? "red" : "black";

  return (
    <div
      className={"Card-container" + (selectable ? " selectable" : "")}
      onClick={onClick}
    >
      <span
        className={"Card" + (selected ? " selected" : "")}
        style={{ border: selected ? "1px solid black" : undefined, color }}
      >
        <span className="Card-half">
          <div className="Card-symbol-col">
            <span className="mx03">{cardNumberSymbol}</span>
            <span>{cardSuit}</span>
          </div>
        </span>
        <div className="Card-half flipped">
          <span className="Card-symbol-col">
            <span className="mx03">{cardNumberSymbol}</span>
            <span>{cardSuit}</span>
          </span>
        </div>
      </span>
    </div>
  );
}
