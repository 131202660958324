import { ClientData, GameName } from "../shared/ClientState";

interface Props {
  playerInfo: ClientData[];
  onGameSelect: (game: GameName) => void;
  me: number;
}

interface GameOption {
  name: GameName;
  minPlayers: number;
  maxPlayers: number;
}

const GAME_OPTIONS: GameOption[] = [
  {
    name: "rock-paper-scissors",
    minPlayers: 2,
    maxPlayers: 2,
  },
  {
    name: "kill-the-landlord",
    minPlayers: 3,
    maxPlayers: 3,
  },
  {
    name: "survival-hangman",
    minPlayers: 2,
    maxPlayers: 12,
  }
];

export default function GameSelect(props: Props) {
  const { playerInfo, onGameSelect, me } = props;
  const playerCount = playerInfo.length;
  return (
    <ul>
      {GAME_OPTIONS.map((game, i) => {
        const canPlay =
          (playerCount >= game.minPlayers && playerCount <= game.maxPlayers) && (me === 0);
        return (
          <li key={i}>
            <button
              disabled={!canPlay}
              onClick={() => {
                onGameSelect(game.name);
              }}
            >
              Start {game.name}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
