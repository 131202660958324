export type Card52 =
  | "A♠️"
  | "2♠️"
  | "3♠️"
  | "4♠️"
  | "5♠️"
  | "6♠️"
  | "7♠️"
  | "8♠️"
  | "9♠️"
  | "10♠️"
  | "J♠️"
  | "Q♠️"
  | "K♠️"
  | "A♥️"
  | "2♥️"
  | "3♥️"
  | "4♥️"
  | "5♥️"
  | "6♥️"
  | "7♥️"
  | "8♥️"
  | "9♥️"
  | "10♥️"
  | "J♥️"
  | "Q♥️"
  | "K♥️"
  | "A♣️"
  | "2♣️"
  | "3♣️"
  | "4♣️"
  | "5♣️"
  | "6♣️"
  | "7♣️"
  | "8♣️"
  | "9♣️"
  | "10♣️"
  | "J♣️"
  | "Q♣️"
  | "K♣️"
  | "A♦️"
  | "2♦️"
  | "3♦️"
  | "4♦️"
  | "5♦️"
  | "6♦️"
  | "7♦️"
  | "8♦️"
  | "9♦️"
  | "10♦️"
  | "J♦️"
  | "Q♦️"
  | "K♦️";

export type Card54 = Card52 | "j" | "J"; // little joker, big joker

export const DECK_52: Card52[] = [
  "A♠️",
  "2♠️",
  "3♠️",
  "4♠️",
  "5♠️",
  "6♠️",
  "7♠️",
  "8♠️",
  "9♠️",
  "10♠️",
  "J♠️",
  "Q♠️",
  "K♠️",
  "A♥️",
  "2♥️",
  "3♥️",
  "4♥️",
  "5♥️",
  "6♥️",
  "7♥️",
  "8♥️",
  "9♥️",
  "10♥️",
  "J♥️",
  "Q♥️",
  "K♥️",
  "A♣️",
  "2♣️",
  "3♣️",
  "4♣️",
  "5♣️",
  "6♣️",
  "7♣️",
  "8♣️",
  "9♣️",
  "10♣️",
  "J♣️",
  "Q♣️",
  "K♣️",
  "A♦️",
  "2♦️",
  "3♦️",
  "4♦️",
  "5♦️",
  "6♦️",
  "7♦️",
  "8♦️",
  "9♦️",
  "10♦️",
  "J♦️",
  "Q♦️",
  "K♦️",
];

export const DECK_54: Card54[] = [...DECK_52, "j", "J"];

export type CardOrdering = {
  [k in Card54]: number;
};
export const DEFAULT_CARD_ORDER = Object.fromEntries(
  DECK_54.map((v, i) => [v, i])
) as CardOrdering;

export const LANDLORD_ORDER = Object.fromEntries(
  "3♠️ 3♣️ 3♥️ 3♦️ 4♠️ 4♣️ 4♥️ 4♦️ 5♠️ 5♣️ 5♥️ 5♦️ 6♠️ 6♣️ 6♥️ 6♦️ 7♠️ 7♣️ 7♥️ 7♦️ 8♠️ 8♣️ 8♥️ 8♦️ 9♠️ 9♣️ 9♥️ 9♦️ 10♠️ 10♣️ 10♥️ 10♦️ J♠️ J♣️ J♥️ J♦️ Q♠️ Q♣️ Q♥️ Q♦️ K♠️ K♣️ K♥️ K♦️ A♠️ A♣️ A♥️ A♦️ 2♠️ 2♣️ 2♥️ 2♦️ j J"
    .split(" ")
    .map((v, i) => [v, i])
) as CardOrdering;

export function sortCards(cards: Card54[], order?: CardOrdering): Card54[] {
  // could just overwrite but this makes typescript happy
  let _order = order || DEFAULT_CARD_ORDER;
  return cards.slice().sort((a, b) => _order[a] - _order[b]);
}

/** Randomly shuffle an array in-place */
export function shuffle<T>(arr: T[]): T[] {
  for (let i = arr.length - 1; i >= 1; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = arr[j];
    arr[j] = arr[i];
    arr[i] = temp;
  }

  return arr;
}
export function multiplicity<T extends string>(
  arr: T[]
): { [k in T]?: number } {
  let result: { [k in T]?: number } = {};
  for (let el of arr) {
    if (!(el in result)) {
      result[el] = 1;
    } else {
      result[el] = result[el]! + 1;
    }
  }

  return result;
}

export type CardNumber =
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "jack"
  | "queen"
  | "king"
  | "ace"
  | "joker";

export const CARD_NUMBER_MAP: { [key in Card54]: CardNumber } = {
  "A♠️": "ace",
  "2♠️": "2",
  "3♠️": "3",
  "4♠️": "4",
  "5♠️": "5",
  "6♠️": "6",
  "7♠️": "7",
  "8♠️": "8",
  "9♠️": "9",
  "10♠️": "10",
  "J♠️": "jack",
  "Q♠️": "queen",
  "K♠️": "king",
  "A♥️": "ace",
  "2♥️": "2",
  "3♥️": "3",
  "4♥️": "4",
  "5♥️": "5",
  "6♥️": "6",
  "7♥️": "7",
  "8♥️": "8",
  "9♥️": "9",
  "10♥️": "10",
  "J♥️": "jack",
  "Q♥️": "queen",
  "K♥️": "king",
  "A♣️": "ace",
  "2♣️": "2",
  "3♣️": "3",
  "4♣️": "4",
  "5♣️": "5",
  "6♣️": "6",
  "7♣️": "7",
  "8♣️": "8",
  "9♣️": "9",
  "10♣️": "10",
  "J♣️": "jack",
  "Q♣️": "queen",
  "K♣️": "king",
  "A♦️": "ace",
  "2♦️": "2",
  "3♦️": "3",
  "4♦️": "4",
  "5♦️": "5",
  "6♦️": "6",
  "7♦️": "7",
  "8♦️": "8",
  "9♦️": "9",
  "10♦️": "10",
  "J♦️": "jack",
  "Q♦️": "queen",
  "K♦️": "king",
  j: "joker",
  J: "joker",
};
