import Idle from "./Idle";

export interface UserInfo {
  username: string;
  alive: boolean;
  id: string;
  beingWaitedOn?: boolean;
}
interface Props {
  users: UserInfo[];
  me: number;
  canKick: boolean;
  onKick: (id: string) => void;
}

export default function PlayerList(props: Props) {
  const { users, me, canKick, onKick } = props;
  return (
    <ol className="shadowed">
      {users.map((user, i) => {
        let extras: string[] = [];
        if (i === me) {
          extras.push("You");
        }
        if (i === 0) {
          extras.push("👑");
        }
        if (!user.alive) {
          extras.push("disconnected");
        }

        let extrasString =
          extras.length > 0 ? "(" + extras.join(", ") + ")" : "";

        return (
          <li key={i}>
            {canKick && i !== me && (
              <input
                type="button"
                value="Kick"
                onClick={() => {
                  onKick(user.id);
                }}
              />
            )}
            <b>{user.username}</b>
            {extrasString}
            {user.beingWaitedOn ? <Idle /> : undefined}
          </li>
        );
      })}
    </ol>
  );
}
