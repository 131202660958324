import { RoomState } from "../shared/ClientState";
import MessageLog from "./MessageLog";
import PlayerList, { UserInfo } from "./PlayerList";
import SubmittableTextInput from "./SubmittableTextInput";

interface Props {
  state: RoomState<any, any>;
  onNameChange: (newName: string) => void;
  onKick: (id: string) => void;
  children: JSX.Element;
}

export default function StatusWrapper(props: Props) {
  const { state, onNameChange, onKick } = props;
  const mixedPlayerlistState: UserInfo[] = state.playerInfo.map((v, i) => {
    const waitingOn = state.requestState.waitingOnPlayer[i] || false;
    return {
      ...v,
      beingWaitedOn: waitingOn,
    };
  });

  return (
    <div>
      <div className="status-bar">
        <SubmittableTextInput
          onSubmit={onNameChange}
          placeholder="Change name"
          confirmText="Set"
        />
        <div className="flex-row">
          <PlayerList
            users={mixedPlayerlistState}
            me={state.index}
            canKick={state.index === 0 && state.gameName === ""}
            onKick={onKick}
          />
          <MessageLog messages={state.messageLog} playerInfo={state.playerInfo}/>
        </div>
      </div>
      {props.children}
    </div>
  );
}
