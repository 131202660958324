import SubmittableTextInput from "../components/SubmittableTextInput";
import { RoomState } from "../shared/ClientState";
import LetterPicker from "./sh/LetterPicker";
import { SHPlayerGameState, SHRequest, SHResponse } from "./sh/shtypes";
import "./SurvivalHangman.css";

interface Props {
  state: RoomState<SHPlayerGameState, SHRequest>;
  send: (msg: SHResponse) => any;
}

export default function SurvivalHangman(props: Props) {
  let { state, send } = props;
  const { requestState, playerGameState, playerInfo } = state;
  const {
    isPlayerOut,
    allowedLetters,
    pickedLetters,
    playerOrder,
    othersWords,
    word,
  } = playerGameState;
  const me = state.index;

  let req = requestState.currentRequest;

  let actionBar = [];
  let canGuess = false;

  if (req === null || req.kind === "play") {
    if (req !== null) canGuess = true;

    actionBar.push(
      <LetterPicker
        lettterSet={allowedLetters}
        pickedLetters={pickedLetters}
        disabled={!canGuess}
        onPick={(letter) => {
          send({
            kind: "pick-letter",
            data: letter,
          });
        }}
      />
    );
  } else {
    switch (req.kind) {
      case "word":
        actionBar.push(
          <SubmittableTextInput
            onSubmit={(text) =>
              send({
                kind: "select-word",
                data: text,
              })
            }
            confirmText="Use this word"
            key={req.kind}
          />
        );
        break;
      case "game-end":
        let winnerName = "Nobody!";
        for (let playerIdx = 0; playerIdx < playerOrder.length; playerIdx++) {
          if (!isPlayerOut[playerIdx]) {
            winnerName = playerInfo[playerIdx].username;
            break;
          }
        }

        actionBar.push(
          <div>
            <h2>{winnerName} Wins!</h2>
            <input
              type="button"
              value="Continue"
              onClick={() => {
                send({
                  kind: "continue",
                });
              }}
            />
          </div>
        );
        break;
    }
  }

  return (
    <div>
      <div>{actionBar}</div>
      <ul className="shadowed">
        {playerOrder.map((playerIdx) => {
          return (
            <li>
              <b>{playerInfo[playerIdx].username}</b>
              <br />
              <code
                className={`${
                  isPlayerOut[playerIdx] ? "st-bad " : ""
                }spaced-letters`}
              >
                {othersWords[playerIdx]}
              </code>
              {playerIdx === me ? (
                <span style={{ marginLeft: "1rem" }}>{`(${word})`}</span>
              ) : undefined}
              <br />
              <SubmittableTextInput
                onSubmit={(text) => {
                  send({
                    kind: "guess-word",
                    data: {
                      player: playerIdx,
                      word: text,
                    },
                  });
                }}
                confirmText="Guess word!"
                disabled={!canGuess || isPlayerOut[playerIdx]}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
