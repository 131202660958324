export interface ClientData {
  username: string;
  alive: boolean;
  id: string;
}

export interface GameSpecificState<PGS, R> {
  requestState: RequestState<R>;
  playerGameState: PGS; // private, player-specific info
}

export interface RoomSpecificState {
  playerInfo: ClientData[];
  messageLog: string[];
  index: number; // which of the above players are YOU, the one I'm talking to?
  gameName: string;
}

export type RoomState<PGS, R> = RoomSpecificState & GameSpecificState<PGS, R>;

export interface RequestState<R> {
  currentRequest: R | null;
  waitingOnPlayer: boolean[]; // other players only know if you are being watied on
}

export type GameName = "rock-paper-scissors" | "kill-the-landlord" | "survival-hangman";

// from client to server
export interface CleanMessage {
  kind: string;
  // instead of just plain any, require some kind of checking
  data?: number | string | boolean | object;
}

export function player(index: number): string {
  return `[player#${index}]`;
};

export function fillUsernames(message: string, playerInfo: ClientData[]) {
  return message.replaceAll(/\[player#(\d+)\]/g, (substr: string, i: string) => {
    let idx = parseInt(i);
    if (isNaN(idx)) return substr;
    if (idx < 0 || idx >= playerInfo.length) return substr;
    return  playerInfo[idx].username;
  });
}